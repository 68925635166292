import Vue from 'vue'
import Vuex, { GetterTree, MutationTree, StoreOptions } from 'vuex'
import UAParser from 'ua-parser-js'

interface WidgetAgent {
  name: string,
  url: string
}

interface Channel {
  name: string,
  settings: Record<string, any>
}

interface Offset {
  x: number,
  y: number
}

interface UA {
  browser: string,
  model: string,
  device: string,
  language: string,
  isMobile: boolean
}

interface State {
  account: string | null
  autoOpen: number | null,
  channels: Array<Channel>,
  cta: string,
  forceCtaDisplay: boolean,
  hideCtaDelay: number | null,
  isOpen: boolean,
  logo: string | null,
  name: string,
  needCta: boolean,
  showCtaDelay: number | null,
  state: string,
  ua: UA | null,
  widgetAgents: Array<WidgetAgent>,
  widgetColor: string,
  widgetLogo: string | null,
  widgetOffset: Offset,
  widgetPosition: string,
  widgetTitle: string,
  widgetSubtitle: string,
  isHidden: boolean
}

const state: State = {
  account: null,
  autoOpen: null,
  channels: [],
  cta: 'Bonjour, comment pouvons-nous vous aider ?',
  forceCtaDisplay: false,
  hideCtaDelay: null,
  isOpen: false,
  logo: null,
  name: '',
  needCta: true,
  showCtaDelay: null,
  state: 'hidden',
  ua: null,
  widgetAgents: [],
  widgetColor: '#FF7464',
  widgetLogo: null,
  widgetOffset: {
    x: 20,
    y: 20
  },
  widgetPosition: 'right',
  widgetTitle: '',
  widgetSubtitle: '',
  isHidden: false
}

const getters: GetterTree<State, State> = {
  account (state): string | null {
    return state.account
  },
  autoOpen (state): number | null {
    return state.autoOpen
  },
  channels (state): Array<Channel> {
    return state.channels
  },
  cta (state): string {
    return state.cta
  },
  forceCtaDisplay (state): boolean {
    return state.forceCtaDisplay
  },
  hideCtaDelay (state): number | null {
    return state.hideCtaDelay
  },
  isOpen (state): boolean {
    return state.isOpen
  },
  isMobile (state): boolean {
    return state.ua ? state.ua.isMobile : false
  },
  isWidgetColorLight (state): boolean {
    const c = state.widgetColor.replace(/^#/, '')
    let sum = parseInt(c[0] + c[1], 16)
    sum += parseInt(c[2] + c[3], 16)
    sum += parseInt(c[4] + c[5], 16)
    return sum > 420
  },
  logo (state): string | null {
    return state.logo
  },
  name (state): string {
    return state.name
  },
  needCta (state): boolean {
    return state.needCta
  },
  showCtaDelay (state): number | null {
    return state.showCtaDelay
  },
  state (state): string {
    return state.state
  },
  ua (state): UA | null {
    return state.ua
  },
  widgetAgents (state): Array<WidgetAgent> {
    return state.widgetAgents
  },
  widgetColor (state): string {
    return state.widgetColor
  },
  widgetLogo (state): string | null {
    return state.widgetLogo
  },
  widgetOffset (state): Offset {
    return state.widgetOffset
  },
  widgetPosition (state): string {
    return state.widgetPosition
  },
  widgetSubtitle (state): string {
    return state.widgetSubtitle
  },
  widgetTitle (state): string {
    return state.widgetTitle
  },
  isHidden (state): boolean {
    return state.isHidden
  }
}

const mutations: MutationTree<State> = {
  initial (state, params: Record<string, any>) {
    state.autoOpen = params.auto_open
    state.cta = params.cta
    state.forceCtaDisplay = params.force_cta_display
    state.hideCtaDelay = params.hide_cta
    state.isHidden = params.is_hidden
    state.isOpen = params.is_open
    state.logo = params.logo
    state.name = params.name
    state.showCtaDelay = params.show_cta
    state.widgetAgents = params.widget_agents || []
    state.widgetSubtitle = params.widget_subtitle
    state.widgetTitle = params.widget_title

    if (params.widget_color) {
      state.widgetColor = params.widget_color
    }

    if (params.widget_logo) {
      state.widgetLogo = params.widget_logo
    }

    if (params.widget_offset_x || params.widget_offset_y) {
      state.widgetOffset = {
        x: state.widgetOffset.x + (params.widget_offset_x || 0),
        y: state.widgetOffset.y + (params.widget_offset_y || 0)
      }
    }

    if (params.widget_position) {
      state.widgetPosition = params.widget_position
    }

    const MOBILE_DEVICES = ['mobile', 'tablet', 'wearable', 'embedded']
    const parser = new UAParser()
    const res = parser.getResult()
    const model = res.device.model || 'PC'
    const isMobile = MOBILE_DEVICES.indexOf(res.device.type as string) > -1

    state.ua = {
      browser: res.browser.name as string,
      model: model,
      device: `${model}/${res.os.name}`,
      language: window.navigator.language,
      isMobile: isMobile
    }
  },
  setAccount (state, value: string) {
    state.account = value
  },
  setChannels (state, list: Array<Channel>) {
    state.channels = list
  },
  setNeedCta (state, value: boolean) {
    state.needCta = value
  },
  setState (state, value: string) {
    state.state = value
  }
}

Vue.use(Vuex)

const store: StoreOptions<State> = {
  getters,
  mutations,
  state,
  strict: process.env.NODE_ENV !== 'production'
}

export default new Vuex.Store<State>(store)
