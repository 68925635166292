const GuestCookie = () => {
  const readGuest = function (kind, account, readonly) {
    // 'gb-zb-guest-([w-]+)=([^;]+)'
    let result = document.cookie.match(new RegExp(`gb-zb-${kind}-${account}=([^;]+)`))
    result && (result = result[1])
    if (readonly !== true && result === null && kind === 'guest') {
      const uuid = uuidv4()
      storeGuest(kind, account, uuid)
      return uuid
    }
    return result
  }

  const storeGuest = function (kind, account, guestUuid) {
    // console.log('STORE GUEST', account)
    const date = new Date()
    date.setTime(date.getTime() + (30 * 24 * 60 * 60 * 1000))
    const cookie = [
      'gb-zb-', kind, '-', account, '=', guestUuid,
      '; expires=', date.toUTCString(),
      '; path=/;'].join('')
    document.cookie = cookie
  }

  const uuidv4 = function () {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
      /[xy]/g, function (c) {
        const r = Math.random() * 16 | 0
        const v = c === 'x' ? r : (r & 0x3 | 0x8)
        return v.toString(16)
      })
  }

  return {
    readGuest: readGuest,
    storeGuest: storeGuest,
    uuidv4: uuidv4
  }
}

export default GuestCookie()
