export default {
  en: {
    Cancel: 'Cancel',
    Send: 'Send',
    chayall_url: 'https://chayall.com',
    default_title: 'Let\'s exchange on the messaging of your choice 👋🏻',
    doc_title_new_msg: 'New message',
    by_chayall: 'by ChayAll',
    errors: {
      file_too_big: 'File is too big',
      general: 'General error'
    },
    form: {
      placeholder: 'Type your message…',
      pick_your_emoji: 'Pick your emoji'
    },
    powered_by_chayall: 'Powered by ChayAll',
    survey: {
      title: 'Hope your exchange went well',
      subtitle: 'Don\'t hesitate to rate ourselves'
    },
    uploads: {
      click: 'Click here to choose a file',
      drag: 'or drag it here'
    }
  },
  fr: {
    Cancel: 'Annuler',
    Send: 'Envoyer',
    chayall_url: 'https://chayall.fr',
    default_title: 'Échangeons sur la messagerie de votre choix 👋🏻',
    doc_title_new_msg: 'Nouveau message',
    by_chayall: 'par ChayAll',
    errors: {
      file_too_big: 'Le fichier est trop gros',
      general: 'Erreur générale'
    },
    form: {
      placeholder: 'Tapez votre message…',
      pick_your_emoji: 'Choisissez un emoji'
    },
    powered_by_chayall: 'Propulsé par ChayAll',
    survey: {
      title: 'Nous espérons que votre échange s\'est bien passé',
      subtitle: 'N\'hésitez pas à nous laisser votre avis'
    },
    uploads: {
      click: 'Cliquez ici pour choisir un fichier',
      drag: 'ou glissez-le ici'
    }
  }
}
