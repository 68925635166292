import Vue from 'vue'
import VueI18n from 'vue-i18n'
import Vuex from 'vuex'

import UAParser from 'ua-parser-js'
import push from 'push.js'
import xss from 'xss'

import GuestCookie from '@/components/guest-cookie'
import messages from '@/i18n'
import pageTitleNotification from '@/components/flash-title'

Vue.use(Vuex)
Vue.use(VueI18n)

const flashTitle = pageTitleNotification()
const i18n = new VueI18n({
  locale: 'fr', // set locale
  fallbackLocale: 'en',
  messages // set locale messages
})

export default {
  namespaced: true,
  state: {
    account: null,
    canType: true,
    channel: null,
    color: '#015CF2',
    emojiPickerShown: false,
    hasPoweredBy: true,
    hsp: 0,
    httpUrl: null,
    isAllowedToPush: false,
    isLoaderGuest: false,
    isLoaderSeen: false,
    isOpen: false,
    isUpload: false,
    logo: null,
    messages: [],
    name: null,
    settings: {},
    step: '', // button or chatbox or feedback
    ua: null,
    uploadUrl: null,
    uploadSize: null,
    userInfos: {
      message: ''
    },
    windowUrl: null,
    wsIsOpen: false,
    wsUrl: null
  },
  getters: {
    account (state) {
      return state.account
    },
    canType (state) {
      if (state.messages.length > 0) {
        const lastItem = state.messages[state.messages.length - 1].rich_content[
          state.messages[state.messages.length - 1].rich_content.length - 1]
        if (lastItem.kind === 'choice') {
          return false
        }
      }
      return true
    },
    channel (state) {
      return state.channel
    },
    color (state) {
      return state.color
    },
    hasPoweredBy (state) {
      return state.hasPoweredBy
    },
    hsp (state) {
      return state.hsp
    },
    httpUrl (state) {
      return state.httpUrl
    },
    isAllowedToPush (state) {
      return state.isAllowedToPush
    },
    isEmojiPickerShown (state) {
      return state.emojiPickerShown
    },
    isLoaderGuest (state) {
      return state.isLoaderGuest
    },
    isLoaderSeen (state) {
      return state.isLoaderSeen
    },
    isOpen (state) {
      return state.isOpen
    },
    isUpload (state) {
      return state.isUpload
    },
    getBotMsgStyle (state) {
      const color = state.color
      return `color: ${color}; border: 1px solid ${color} !important`
    },
    logo (state) {
      return state.logo
    },
    messages (state) {
      return state.messages
    },
    name (state) {
      return state.name
    },
    settings (state) {
      return state.settings
    },
    step (state) {
      return state.step
    },
    ua (state) {
      return state.ua
    },
    uploadUrl (state) {
      return state.uploadUrl
    },
    uploadSize (state) {
      return state.uploadSize
    },
    userMsg (state) {
      return state.userInfos.message
    },
    windowUrl (state) {
      return state.windowUrl
    },
    wsUrl (state) {
      return state.wsUrl
    }
  },
  mutations: {
    initial (state, params) {
      let color = params.settings.color

      // Variables for red, green, blue values
      let r
      let g
      let b
      let hsp = null

      // Check the format of the color, HEX or RGB?
      if (color.match(/^rgb/)) {
        // If HEX --> store the red, green, blue values in separate variables
        color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/)

        r = color[1]
        g = color[2]
        b = color[3]
      } else {
        // If RGB --> Convert it to HEX: http://gist.github.com/983661
        color = +('0x' + color.slice(1).replace(
          color.length < 5 && /./g, '$&$&'))

          r = color >> 16
          g = color >> 8 & 255
          b = color & 255
      }

      // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
      hsp = Math.sqrt(
        0.299 * (r * r) +
        0.587 * (g * g) +
        0.114 * (b * b)
      )

      if (hsp < 240) {
        state.color = params.settings.color
        state.hsp = hsp
      }
      state.logo = params.settings.logo
      state.name = params.settings.name
      state.isOpen = params.is_open
      state.settings = params.settings
      state.httpUrl = params.httpUrl
      state.uploadUrl = params.uploadUrl
      state.uploadSize = params.uploadSize
      state.wsUrl = params.wsUrl

      if (params.settings.powered_by === false) {
        state.hasPoweredBy = params.settings.powered_by
      }

      const MOBILE_DEVICES = ['mobile', 'tablet', 'wearable', 'embedded']
      const parser = new UAParser()
      const res = parser.getResult()
      const model = res.device.model || 'PC'
      const isMobile = MOBILE_DEVICES.indexOf(res.device.type) > -1

      state.windowUrl = params.windowUrl || window.location.href
      state.ua = {
        browser: res.browser.name,
        model: model,
        device: `${model}/${res.os.name}`,
        language: window.navigator.language,
        isMobile: isMobile
      }
    },
    disablePreviousChoices (state) {
      if (state.messages) {
        state.messages.forEach((msg) => {
          // console.log('MESSSAGE', msg)
          if (msg.richContent) {
            msg.richContent.forEach((elt) => {
              elt.disabled = true
              // if (elt.kind === 'timeslots') {
              //   elt.timeslots.forEach((timeslot) => {
              //     timeslot.disabled = true
              //   })
              // }
            })
          }
        })
      }
    },
    setAccount (state, value) {
      state.account = value
    },
    setChannel (state, value) {
      state.channel = value
    },
    toggleUpload (state) {
      state.isUpload = !state.isUpload
    },
    updateIsAllowedToPush (state, value) {
      state.isAllowedToPush = value
    },
    updateCanType (state, value) {
      state.canType = value
    },
    updateEmojiPickerShown (state, value) {
      if (value === undefined) {
        value = !state.emojiPickerShown
      }
      state.emojiPickerShown = value
    },
    updateLoader (state, value) {
      state.isLoaderSeen = value
    },
    updateLoaderGuest (state, value) {
      state.isLoaderGuest = value
    },
    updateMsg (state, msg) {
      state.messages.push(msg)
    },
    updateStep (state, step) {
      state.step = step
    },
    updateUserMsg (state, message) {
      state.userInfos.message = message
    },
    updateWsIsOpen (state, value) {
      state.wsIsOpen = value
    }
  },
  actions: {
    changeStep ({ commit, state }, args) {
      commit('updateStep', args.step)
      // if (args.step === 'chatbox') {
      //   Vue.http.post(`zenchat/${state.account}/`, {})
      // }
    },
    checkPing ({ dispatch }, args) {
      let data = []
      if ('history' in args.data) {
        data = args.data.history
      } else {
        data = args.data
        if (Array.isArray(data) === false) {
          data = [data]
        }
      }
      data.forEach((msg) => {
        if (msg.rich_content !== null && msg.rich_content !== undefined) {
          msg.rich_content.forEach((elt) => {
            if (elt.kind === 'ping') {
              // ping kind for waiting the bot do something async
              window.setTimeout(() => {
                dispatch({
                  type: 'sendMsg',
                  content: 'pong',
                  richContent: {
                    kind: 'pong'
                  }
                })
              }, 2000)
            }
          })
        }
      })
    },
    handleMessages ({ commit, dispatch, state }, args) {
      const data = JSON.parse(args.data.data)
      if (data.cmd === 'join') {
        const guest = data.guest
        GuestCookie.storeGuest('guest', state.settings.bot_identifier, guest)
      }
      if (data.cmd === 'join' || (data.cmd === 'event' &&
          (data.kind === 'message' || data.kind === 'bot_msg' ||
           data.kind === 'gbagent_msg' || data.kind === 'guest_msg' ||
           data.kind === 'zencall' || data.kind === 'form' ||
           data.kind === 'action' || data.kind === 'formerror' ||
           data.kind === 'zencallerror'))) {
        dispatch('loopMsgs', data)
      }
      dispatch({
        type: 'checkPing',
        data: data
      })
    },
    loopMsgs ({ commit, state }, data) {
      commit('updateLoader', true)
      let x = 0
      const loopArray = (arr) => {
        appendMsg(arr[x], () => {
          // set x to next item
          x++

          // any more items in array? continue loop
          if (x < arr.length) {
            loopArray(arr)
          } else {
            // state.isLoaderSeen = false
            commit('updateLoader', false)
          }
        })
      }

      const appendMsg = async (item, callback) => {
        commit('updateLoaderGuest', item && item.kind === 'guest_msg')
        window.setTimeout(() => {
          // progressive display of the history
          if (item && (item.kind === 'message' ||
              item.kind === 'bot_msg' ||
              item.kind === 'gbagent_msg' ||
              item.kind === 'guest_msg')) {
            const msg = Object.assign({}, item)
            if (msg.rich_content === null) {
              // case of survey
              commit('updateLoader', false)
            } else {
              // all other cases
              msg.rich_content.forEach((elt) => {
                let inputTypeTooltip = null
                if (elt.input_type !== undefined) {
                  inputTypeTooltip = elt.input_type
                }
                state.inputTypeTooltip = inputTypeTooltip
                let helpText = null
                if (elt.help_text !== undefined) {
                  helpText = elt.help_text
                }
                state.helpText = helpText
                // console.log('>>>', item.item, msg)
                let disabled = false
                if (data.history !== undefined &&
                    x < data.history.length - 1) {
                  disabled = true
                }
                elt.disabled = disabled
                elt.itemId = msg.uuid
                elt.from = item.from
              })
              commit('updateMsg', msg)
            }
          }
          if (item !== undefined) {
            state.lastEventId = item.msg_id
          }
          if (callback !== undefined) {
            callback()
          } else {
            commit('updateLoader', false)
          }
        }, 50)
      }

      if (data.history !== undefined) {
        loopArray(data.history)
      } else {
        appendMsg(data)
      }
    },
    initWebsocket ({ commit, state, dispatch, getters }) {
      // for debug
      // commit('updateWsIsOpen', true)
      if (state.wsIsOpen === false) {
        this._vm.$socket.open(state.wsUrl)
        this._vm.$socket.onopen = function (e) {
          const params = {
            cmd: 'join',
            url: state.windowUrl,
            chat: state.settings.bot_identifier,
            guest: GuestCookie.readGuest('guest', state.settings.bot_identifier),
            lastevent: null, // that.lastEventId,
            infos: {
              language: state.ua.language,
              device: state.ua.device,
              browser: state.ua.browser,
              origin: state.windowUrl
            }
          }
          this.send(JSON.stringify(params))
        }
        this._vm.$socket.onmessage = function (data, flags, number) {
          dispatch({
            type: 'webchat/handleMessages',
            data: data
          }, { root: true })
          if (getters.isAllowedToPush === true) {
            flashTitle.on(`📫 ${i18n.t('doc_title_new_msg')}`, 1000)
            const jsonData = JSON.parse(data.data)
            if (!('history' in jsonData) &&
                'rich_content' in jsonData &&
                jsonData.rich_content !== null &&
                jsonData.rich_content.length > 0 &&
                'txt' in jsonData.rich_content[0]) {
              push.create(jsonData.rich_content[0].txt)
            }
          }
        }
        commit('updateWsIsOpen', true)
      }
    },
    sendMsg ({ commit, state }, args) {
      commit('updateCanType', false)
      const that = this
      const options = {
        whiteList: [], // empty, means filter out all tags
        stripIgnoreTag: true, // filter out all HTML not in the whilelist
        stripIgnoreTagBody: ['script'] // the script tag is a special case, we need
        // to filter out its content
      }
      let sanitized = {}
      if (typeof args.richContent === 'object') {
        for (const key in args.richContent) {
          let cleaned
          if (typeof args.richContent[key] === 'object') {
            cleaned = {}
            for (const key_ in args.richContent[key]) {
              cleaned[key_] = xss(args.richContent[key][key_], options)
            }
          } else {
            cleaned = xss(args.richContent[key], options)
          }
          sanitized[key] = cleaned
        }
      } else if (typeof args.richContent === 'string') {
        sanitized = xss(args.richContent, options)
      } else if (typeof args.richContent === 'number') {
        sanitized = args.richContent
      }
      let richContent
      if (typeof args.richContent === 'string') {
        richContent = JSON.stringify([{
          kind: 'txt',
          txt: sanitized
        }])
      } else if (args.richContent !== null) {
        richContent = JSON.stringify([sanitized])
      } else {
        richContent = null
      }
      const jString = JSON.stringify({
        headers: {
          language: navigator.language,
          timezone: new Date().getTimezoneOffset()
        },
        cmd: 'message',
        url: this.windowUrl,
        content: args.content,
        rich_content: richContent
      })
      that._vm.$socket.send(jString)
      commit('updateLoaderGuest', true)
      commit('updateLoader', true)
    }
  }
}
