import Vue from 'vue'
import VueI18n from 'vue-i18n'
import VueResource from 'vue-resource'

import messages from './i18n'
import store from './store'

// TO BE MOVED WITH THE FUTURE CHANNELS NPM PACKAGE (Vue component install)
import webchat from '@/store/modules/webchat'

declare global {
  interface Navigator {
    userLanguage?: string;
  }
  interface Window {
    webpackHotUpdate?: string;
  }
}

let account: string | null
let elt: HTMLElement
let found = false
let skill: string | null = null
let version: string | null = null
document.querySelectorAll('script[data-chayall-account]').forEach(tag => {
  if (found === false && tag.getAttribute('data-chayall-done') === null) {
    const tmp = (tag.getAttribute('data-chayall-account') || '').split(':')
    version = tmp.length > 1 ? tmp[0] : null
    account = tmp.length > 1 ? tmp[1] : tmp[0]
    skill = tmp.length > 2 ? tmp[2] : null
    tag.setAttribute('data-chayall-done', 'true')
    found = true
    elt = document.createElement('div')
    document.body.appendChild(elt)
  }
})

Vue.config.productionTip = false

Vue.use(VueI18n)
const i18n = new VueI18n({
  locale: (navigator.language || navigator.userLanguage || '').split('-')[0],
  fallbackLocale: 'en',
  messages // set locale messages
})

const init = () => {
  if (elt) {
    Vue.use(VueResource)
    Vue.http.options.root = `${process.env.VUE_APP_BACKEND_URL}`

    if (!window.webpackHotUpdate) {
      const head = document.querySelector('head')
      const zc = document.createElement('link')
      zc.rel = 'stylesheet'
      zc.href = `${process.env.VUE_APP_FRONT_URL}/css/chayall.css`
      if (head) {
        head.appendChild(zc)
      }
    }

    let url = `${process.env.VUE_APP_BACKEND_URL}/widget/${account}`

    if (version === 'v2') {
      url = `${process.env.VUE_APP_BACKEND_URL}/widget/v2/${account}`

      if (skill !== null) {
        url += `/${skill}`
      }
    }

    Vue.http.get(url)
    .then((response: Response) => response.json())
    .then((params: any) => {
      if (params.is_open === true) {
        const App = async () => await import(`./App${ params.widget_version === '2' ? 'V2' : 'V1' }.vue`)
        new Vue({
          i18n,
          store,
          render: h => h(App),
          beforeMount () {
            params.httpUrl = `${process.env.VUE_APP_BACKEND_URL}`
            params.wsUrl = `${process.env.VUE_APP_BACKEND_WS}`
            params.uploadUrl = `${process.env.VUE_APP_UPLOAD_URL}`
            this.$store.commit('setAccount', account)
            this.$store.commit('initial', params)
            this.$store.commit('setChannels', params.channels)

            // TO BE MOVED WITH THE FUTURE CHANNELS NPM PACKAGE (Vue component install)
            this.$store.registerModule('webchat', webchat)
          }
        }).$mount(elt)
      }
    })
  }
}

init()
